
import { Component } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import SubModule from '@/components/SubModule.vue';
import { IMenuItem } from '@/components/Menu.vue';
import routes from './routes';
import store from './store';
import { EntityStoreGetters } from '@/base';
import { getFullName, UserType } from '@/modules/user';
import { getEnumByName } from '@/util';
import { EmailTemplateType } from '@/modules/emailtemplate';
import { store as mainStore, Getters } from '@/store';
import { ReceiverOption } from '../json';

@Component
export default class DocumentTranslation extends SubModule
{
  private get activeItem(): typeof store[EntityStoreGetters.ACTIVE_ITEM]
  {
    return store[EntityStoreGetters.ACTIVE_ITEM];
  }

  protected _getRoutes(): RouteConfig[]
  {
    return routes;
  }

  protected _getDynamicMenuItems(): IMenuItem[]
  {
    // E-mail route as single tab
    if (this.$route.meta && this.$route.meta.isEmailRoute === true)
    {
      const emailTemplateType = getEnumByName(EmailTemplateType, this.$route.params.emailTemplateType);

      return [{
        title: emailTemplateType ? this.$root.$t(`module.emailtemplate.enums.EmailTemplateType.${emailTemplateType}`).toString() : this.$t('email.title').toString(),
        icon: '$vuetify.icons.sendEmail',
        route: this.$route.fullPath,
      }];
    }

    const activeItem = this.activeItem;
    if (activeItem)
    {
      if (activeItem.$isCreated())
      {
        const edit = {
            title: `${activeItem.$original.contractNo}`,
            icon: `$vuetify.icons.edit`,
            route: `/documenttranslation/${activeItem.id}`,
          };

        const fileAttachmentCollectionOriginal = {
            title: this.$t('fileAttachmentCollectionOriginal.title').toString(),
            icon: '$vuetify.icons.attachment',
            route: `/documenttranslation/${activeItem.id}/fileattachmentcollectionoriginal`,
          };

        const fileAttachmentCollectionTranslated = {
            title: this.$t('fileAttachmentCollectionTranslated.title').toString(),
            icon: '$vuetify.icons.attachment',
            route: `/documenttranslation/${activeItem.id}/fileattachmentcollectiontranslated`,
          };

        const invoiceContact = {
            title: this.$t('invoiceContact.title').toString(),
            icon: `$vuetify.icons.contact`,
            route: `/documenttranslation/${activeItem.id}/invoicecontact`,
          };

        const creditContact = {
            title: this.$t('creditContact.title').toString(),
            icon: `$vuetify.icons.contact`,
            route: `/documenttranslation/${activeItem.id}/creditcontact`,
          };

        if (this.isAllowForAdminsAndOfficeWorkers())
        {
          const items: IMenuItem[] = [];
          items.push(edit);
          items.push(fileAttachmentCollectionOriginal);
          items.push(fileAttachmentCollectionTranslated);
          if (activeItem.invoiceReceiverOption === ReceiverOption.ALTERNATIVE_ADDRESS)
          {
            items.push(invoiceContact);
          }
          if (activeItem.creditReceiverOption === ReceiverOption.ALTERNATIVE_ADDRESS)
          {
            items.push(creditContact);
          }
          return items;
        }
        return[edit, fileAttachmentCollectionOriginal, fileAttachmentCollectionTranslated];
      }
      else
      {
        return[{
          title: this.$t('form.titleNew').toString(),
          icon: `$vuetify.icons.add`,
          route: `/documenttranslation/new`,
        }];
      }
    }
    return [];
  }

  private isAllowForAdminsAndOfficeWorkers(): boolean
  {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.OFFICE_WORKER || currentUserType === UserType.ADMIN;
  }
}
